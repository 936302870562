import { SignInView } from "~/views";
import { SignInModel } from "~/models";

import type {
  ActionFunction,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";

export const meta: MetaFunction = () => ({
  title: "pac.app | SignIn",
});

export const action: ActionFunction = async ({ request, params }) => {
  return await SignInModel.ActionController(request);
};

export const loader: LoaderFunction = async ({ request, params }) => {
  return await SignInModel.LoaderController(request);
};

export default function () {
  return <SignInView />;
}
