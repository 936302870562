import { useEffect, useState } from "react";
import { Link, useActionData, useTransition } from "@remix-run/react";

import { fireToast } from "~/global/utils";
import { Button, TextInput, Checkbox } from "~/components/ui";

import { IndexViewContainer, SignInForm } from "./styles";
import { Toaster } from "react-hot-toast";

export function SignInView() {
  const actionData = useActionData();
  const transition = useTransition();

  const [location, setLocation] = useState("");

  useEffect(() => {
    setLocation(window.location.hostname);
    if (actionData?.toast) fireToast(actionData?.toast);
  }, [actionData]);

  return (
    <IndexViewContainer>
      <Toaster position="top-right" containerStyle={{ zIndex: 99999 }} />

      <h1>
        {location.includes("pca")
          ? "PAC - Plano anual de contratações"
          : "Gestor de Atas"}
      </h1>

      <SignInForm method="post">
        <h2>Faça seu login</h2>

        <TextInput
          name="email"
          placeholder="email@email.com"
          label="E-mail"
          error={actionData?.error?.email}
        />

        <TextInput
          name="password"
          type="password"
          placeholder="mypassword123"
          label="Senha"
          error={actionData?.error?.password}
          style={{ marginTop: "0.5rem" }}
        />

        <div className="keepConnected">
          <Checkbox defaultChecked name="keepConnected" />
          <label>Manter conectado</label>
        </div>

        <Button
          name="_action"
          value="signInUser"
          isLoading={transition.state !== "idle"}
        >
          Entrar
        </Button>
        <Link to="/auth/forgot">Esqueceu sua senha?</Link>
      </SignInForm>
    </IndexViewContainer>
  );
}
